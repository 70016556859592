import { fadeInDown, fadeInRight } from './../../../../animation.components';
import { Router } from '@angular/router'
import { Select, Store } from '@ngxs/store'
import { Component, OnInit, HostListener, OnDestroy } from '@angular/core'
import { Observable, Subscription, Subject } from 'rxjs'
import { Navigate } from '@ngxs/router-plugin';
import { Guid } from 'guid-typescript'
import { takeUntil } from 'rxjs/operators'
import * as moment from 'moment'
import { DatePipe } from '@angular/common'

// to const
// interface navItems {
//   name: string,
//   link: string,
//   roles?: User['roleName'][]
// }

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.styl'],
  animations: [fadeInDown, fadeInRight]
})
export class HeaderComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<void> = new Subject<void>()

  @Select(state => state.router.state) router$: Observable<Router>

  subs = new Subscription()

  logoType = 'usual'

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.isStatic = window.scrollY > 0
  }

  isStatic = false
  navs = []

  constructor(
    private store: Store,
    public datepipe: DatePipe
  ) { }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.subs.unsubscribe()
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }

  goto(route: string, isNotify = false) {
    this.store.dispatch(new Navigate([`/${route}`]))
  }
}
