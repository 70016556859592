import { InjectionToken } from '@angular/core'
import { Notyf } from 'notyf'

export const NOTYF = new InjectionToken<Notyf>('NotyfToken')

export function notyfFactory(): Notyf {
    return new Notyf({
        duration: 5000,
        types: [
            {
                type: 'info',
                backgroundColor: '#0277bd',
                icon: false
            },
            {
                type: 'success',
                backgroundColor: '#2e7d32',
                icon: false
            },
            {
                type: 'warn',
                backgroundColor: '#f9a825',
                icon: false
            },
            {
                type: 'error',
                backgroundColor: '#c62828',
                icon: false
            }
        ]
    });
}
