import { API_CAPTCHA_VALIDATE, API_ACCOUNT_PROPOSAL_ORGANIZATION_REGISTER } from './../../../route/api.path';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class DataService {

  constructor(private http: HttpClient) { }

  captchaValidate(payload: any): Observable<any> {
    return this.http.post(API_CAPTCHA_VALIDATE, payload)
  }

  createProposalOrganizationRegister(payload: any): Observable<any> {
    return this.http.post(API_ACCOUNT_PROPOSAL_ORGANIZATION_REGISTER, payload)
  }
}
