export const wireTransferCashSymbolType = [
  { name: 'Продукты, мебель, книги, одежда, цветы', Id: '02' },
  { name: 'Услуги. Детский сад, школа, оплата питания, ЖКХ, электроэнергия, Аренда помещений. Билеты: транспорт, театр, кино, туры.', Id: '11' },
  { name: 'ПАТЕНТ, Налоги, Штрафы, постановления(суд, прокуратура), Взносы (все). СТРАХОВАНИЕ. ФИНАНСОВАЯ ПОМОЩЬ УЧРЕДИТЕЛЯ', Id: '12' },
  { name: 'Погашение кредитов по договору, возврат займов, ПОПОЛНЕНИЕ КАРТ для ПОГАШЕНИЯ КРЕДИТА', Id: '14' },
  { name: 'Покупка дома, квартиры, гаража, машиноместа, земельного участка', Id: '15' },
  { name: 'Пополнения счетов вкладов', Id: '16' },
  { name: 'Поступления денежных средств от продажи ценных бумаг', Id: '20' },
  { name: 'Пополнение карт сторонних банков.Пополнение счетов 40817', Id: '31' },
  { name: 'Пополнение карт ЮНИСТРИМ. УСЛУГИ БАНКА (комиссия, предоставление выписки, открытие счета, закрытие счета)', Id: '32' },
]

export const cashRUB = { name: 'Российский рубль', Id: 0 }
export const cashUSD = { name: 'Доллар США', Id: 1 }
export const cashEUR = { name: 'Евро', Id: 2 }
export const cashUZS = { name: 'Узбекский Сум', Id: 3 }
export const cashTJS = { name: 'Таджикский Сомони', Id: 4 }

export const operationtitleBottomArr = [
  { char: '0', title: 'операций'},
  { char: '1', title: 'операция'},
  { char: '2', title: 'операции'},
  { char: '3', title: 'операции'},
  { char: '4', title: 'операции'},
  { char: '5', title: 'операций'},
  { char: '6', title: 'операций'},
  { char: '7', title: 'операций'},
  { char: '8', title: 'операций'},
  { char: '9', title: 'операций'}
]

export const fileEx = [
  '.xlsx'
]

export const keyBoardRus = `йцукенгшщзхъфывапролджэ\\ячсмитьбю.ЙЦУКЕНГШЩЗХЪФЫВАПРОЛДЖЭ\ЯЧСМИТЬБЮ.ЙЦУКЕНГШЩЗХЪФЫВАПРОЛДЖЭ/ЯЧСМИТЬБЮ,!"№;%:?*()_+`

export const keyBoardEng = `qwertyuiop[]asdfghjkl;'\\zxcvbnm,./QWERTYUIOP[]ASDFGHJKL;'\ZXCVBNM,./QWERTYUIOP{}ASDFGHJKL:"|ZXCVBNM<>?!@#$%^&*()_+`

export const largeMonths = [1, 3, 5, 7, 8, 10, 12]
