import { Directive, OnInit, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[appAutocomplete]'
})
export class AutocompleteDirective implements OnInit {

  @Input() autocomplete: 'off' | 'on' | 'new-password'

  private isChrome = navigator.userAgent.includes('Chrome')

  constructor(private el: ElementRef) {}

  ngOnInit() {

    // if (!this.isChrome) return

    const nativeEl = this.el.nativeElement
    const isAutoCompleteDisabled = this.autocomplete === 'off' || nativeEl.getAttribute('autocomplete') === 'off' ||
    this.autocomplete === 'new-password' || nativeEl.getAttribute('autocomplete') === 'new-password'

    if (isAutoCompleteDisabled)
      setTimeout(() => {
        nativeEl.setAttribute('autocomplete', 'new-password')
      })
  }
}
