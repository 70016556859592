import {NgModule} from '@angular/core'
import {RouterModule, Routes} from '@angular/router'
import { UnistreamComponent } from 'src/app/pages/unistream/unistream.component'



const routes: Routes = [
  {path: '', pathMatch: 'full', redirectTo: '/unistream'},
  {path: 'unistream', component: UnistreamComponent, data: {animation: 'Home'}},



//     // Операции
//     { path: 'operation/:operationName/:step', component: UnistreamOperationComponent, canActivate: [AuthGuard]},
//     { path: 'operation/:operationName/:step/:id', component: UnistreamOperationComponent, canActivate: [AuthGuard]},
//     { path: 'operation/:operationName', pathMatch: 'full', redirectTo: 'operation/:operationName/1' },

  //   {path: 'transfer/:step', component: UnistreamOperationComponent, data: {animation: 'Transfer'}, canActivate: [AuthGuard]},
  //   { path: 'transfer', pathMatch: 'full', redirectTo: 'transfer/1' },

  // /* СЧЕТА */

  // // Универсальное пополнение
  // { path: 'transfer/:step', component: UnistreamOperationComponent, data: {animation: 'Transfer'}, canActivate: [AuthGuard]},
  // { path: 'transfer', pathMatch: 'full', redirectTo: 'transfer/1' },

  // /* КАРТЫ */

  // // Универсальное пополнение
  // { path: 'cashtocard/:step', component: UnistreamOperationComponent, data: { animation: 'Transfer' }, canActivate: [AuthGuard] },
  // { path: 'cashtocard', pathMatch: 'full', redirectTo: 'cashtocard/1' },

  // // Тинькофф
  // { path: 'tcs/:step', component: UnistreamOperationComponent, canActivate: [AuthGuard] },
  // { path: 'tcs', pathMatch: 'full', redirectTo: 'tcs/1' },

  // // Банк Русский Стандарт
  // { path: 'brs/:step', component: UnistreamOperationComponent, canActivate: [AuthGuard] },
  // { path: 'brs', pathMatch: 'full', redirectTo: 'brs/1' },

  // // Модульбанк
  // { path: 'modulbank/:step', component: UnistreamOperationComponent, canActivate: [AuthGuard] },
  // { path: 'modulbank', pathMatch: 'full', redirectTo: 'modulbank/1' },

  // /* КРЕДИТЫ */

  // // Универсальное погашение
  // { path: 'universalloanrepayment/:step', component: UnistreamOperationComponent, canActivate: [AuthGuard] },
  // { path: 'universalloanrepayment', pathMatch: 'full', redirectTo: 'universalloanrepayment/1'},

  // // Тинькофф
  // { path: 'tcs-loan/:step', component: UnistreamOperationComponent, canActivate: [AuthGuard] },
  // { path: 'tcs-loan', pathMatch: 'full', redirectTo: 'tcs-loan/1' },

  // // Банк Русский Стандарт
  // { path: 'brsaccount/:step', component: UnistreamOperationComponent, canActivate: [AuthGuard] },
  // { path: 'brsaccount', pathMatch: 'full', redirectTo: 'brsaccount/1' },

  // // ОТП Банк
  // { path: 'otpbank/:step', component: UnistreamOperationComponent, canActivate: [AuthGuard] },
  // { path: 'otpbank', pathMatch: 'full', redirectTo: 'otpbank/1' },

  // // РН Банк
  // { path: 'renobank/:step', component: UnistreamOperationComponent, canActivate: [AuthGuard] },
  // { path: 'renobank', pathMatch: 'full', redirectTo: 'renobank/1' },

  // /* ПАТЕНТЫ */

  // // Оплата
  // { path: 'patent/:step', component: UnistreamOperationComponent, canActivate: [AuthGuard] },
  // { path: 'patent', pathMatch: 'full', redirectTo: 'patent/1'},

  // // Оплата(не требует поиска ИФНС)
  // { path: 'patent-standart/:step', component: UnistreamOperationComponent, canActivate: [AuthGuard] },
  // { path: 'patent-standart', pathMatch: 'full', redirectTo: 'patent-standart/1'},

  // /* ... */

//   { path: 'cashbox-queue', component: CashboxQueueComponent, canActivate: [AuthGuard]},
//   { path: 'queue-1c', component: Queue1cComponent, canActivate: [AuthGuard]},
//   { path: 'settings', component: PersonalAreaComponent, canActivate: [AuthGuard]},
//   { path: 'operations', component: OperationsComponent, canActivate: [AuthGuard]},
//   { path: 'tasks', component: TasksComponent, canActivate: [AuthGuard]},
//   { path: 'stats', component: StatsComponent, canActivate: [AuthGuard]},
//   { path: 'support', component: SupportComponent, canActivate: [AuthGuard]},
//   { path: 'restore/:url', component: RestorePasswordComponent},
//   { path: '**', component: NotFoundComponent, data: {animation: '404'}, canActivate: [AuthGuard]},
]


// const authRoutes: Routes = [{
//   path: 'auth', component: AuthBoxComponent, data: {animation: 'auth'}, children: [
//     {path: 'login', component: AuthComponent, data: {subAnimation: 'login'}},
//     {path: 'register', component: AuthComponent, data: {subAnimation: 'register'}},
//     {path: 'forgot', component: AuthComponent, data: {subAnimation: 'forgot'}},
//   ]
// }]

@NgModule({
  imports: [
    // RouterModule.forChild(authRoutes),
    RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
