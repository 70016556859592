<div class="uni-wrapper">
  <div class="left-block-uni-wrapper d-inline-block w-50">
    <div class="left-block-uni-wrapper-img">
      <h1 class="f-36">Свобода переводов!</h1>
      <p class="subtitle-uni">
        Онлайн сервис для Банковских Платежных Агентов<br>
        Зарегистрируйся на портале и начинай зарабатывать вместе с Юнистрим!
      </p>
    </div>
  </div>
  <div class="right-block-uni-wrapper d-inline-block w-50">
    <div class="right-block-uni">
      <h1 class="right-uni-title mb-25">
        <span>
          Добро пожаловать в NABIX Partner!
        </span>
      </h1>

      <p class="info-uni">
        <span class="right-uni-subtitle opacity-0-75">
          Для создания заявки на регистрацию БПА в системе NABIX Partner, пожалуйста, заполните все необходимые поля, чтобы мы могли связаться с Вами.
        </span>
      </p>

      <form
        class=""
        (submit)="onSubmit()"
        autocomplete="on"
        [formGroup]="formGroup"
      >

        <section>
          <app-input
            label="ФИО контактного лица" placeholder="Иванов Иван Иванович"
            width="half" wrapperClass="inputBox-group"
            name="FullName"
            [parentForm]="formGroup"
            autocomplete="new-password"
          ></app-input>

        <app-input
        label="Наименование Компании" placeholder="ОАО ПРИМЕР"
        width="half" wrapperClass="inputBox-group"
        name="OrgName"
        [parentForm]="formGroup"
        autocomplete="new-password"
      ></app-input>

          <app-input
            label="Телефон контактного лица" placeholder="+7 966 1234 56 78" type="tel"
            width="full" wrapperClass="inputBox-group d-inline-block w-48 mr-4"
            name="Phone"
            [parentForm]="formGroup"
            autocomplete="new-password"
            maskPrefix="+7"
            mask=" (000)-000-0000"
          ></app-input>

          <app-input
            label="Почта контактного лица" placeholder="email@email.ru" type="text"
            width="full" wrapperClass="inputBox-group d-inline-block w-48"
            name="Email"
            [parentForm]="formGroup"
            autocomplete="new-password"
          ></app-input>

        </section>

        <div class="d-flex mt-12">
          <div class="d-inline-block w-48 mr-4">
            <re-captcha (resolved)="captchaCahnged($event)" [siteKey]="captchaSiteKey"></re-captcha>
          </div>
          <div class="d-inline-block w-48">
            <button
            type="submit"
            class="btn btn--unfilled btn-proposal m-auto"
            [disabled]="formGroup.invalid"
          >
          Оставить заявку
          </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
