import { Directive, OnInit, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[appReadonly]'
})
export class ReadonlyDirective implements OnInit {

  @Input() readonly: boolean

  private isChrome = navigator.userAgent.includes('Chrome')

  constructor(private el: ElementRef) {}

  ngOnInit() {
    const nativeEl = this.el.nativeElement
    const isReadonly = this.readonly || nativeEl.getAttribute('readonly') === 'readonly'

    if (isReadonly)
      setTimeout(() => {
        nativeEl.setAttribute('readonly', 'readonly')
      })
  }
}
