export const errorMessages = {
    validateEng: 'Выберите ENG раскладку',
    validateSpace: 'Вводите без пробелов',
    validateAdult: 'Не исполнилось 18 лет',
    validateAdultMin: 'Неверный год рождения',
    validatePassport: 'Паспорт просрочен',
    kkmNotFound: 'Сервер печати чеков не активен либо истек сертификат',
    failedAddQueue: 'Не удалось добавить данные в очередь',
    pwdMin: 'Пароль должен быть больше 6 символов',
    pwrDigAndLetterErr: 'Пароль должен состоять из букв и цифр',
    pwdNotMatch: 'Новый пароль не совпадает с подтверждением',
    signServiceNotActive: 'Сервис US2Agent не активен',
    hardwareEmpty: 'Не удалось опрeделить параметры компьютера',
    checkUS2Agent: 'Проверьте активность службы US2Agent',
    fillAllRow: 'Заполните все поля',
    cantFindFile: 'Не удалось найти файл',
    cantDownloadFile: 'Не удалось скачать файл',
    cancelOnlyCreatedReport: 'Отменить можно только отчеты в статусе создания',
    forbiddenFile: 'Нет доступа к файлу',
    forbidden: 'Доступ запрещен',
    commissionBpaFailed: 'Не удалось расчитать комиссию БПА',
    wrongBalanceCordFormat: 'Неверный формат для документа баланса',
    hardwareNotFound: 'Не удалось определить параметры компьютера',
    signServiceHardwareError: 'Не удалось идентифицировать компьютер для работы KKM сервера',
    kkmCheckError: 'Не удалось распечатать чек',
    orgTariffFieldInvalid: 'Тарифные поля БПА заполнены неверно',
    alreadyConfirm: 'Операция уже была подтвержедана',
    cantConfirm: 'Невозможно подтвердить операцию',
    documentExistError: 'Документ уже сохранен для другого клиента',
    clientCreateError: 'Не удалось создать клиента',
    clientDocumentCreateError: 'Не удалось добавить документ для клиента',
    clientUpdateError: 'Не удалось обновить клиента',
    clientDocumentUpdateError: 'Не удалось обновить документ для клиента',
    operationNotExist: 'Операция не существует',
    operationNotSetIn: 'Операция недоступна',
    clientNotExist: 'Клиента с такими данными не существует',
    cardInvalid: 'Невалидная карта',
    defaultError: 'Ошибка при отправке данных',
    needSignIn: 'Необходима авторизация',
    setPrintError: 'Не удалось сделать запись о печати чека',
    downloadDocErr: 'Не удалось сформировать документ',
    cantEditAmount: 'Смена сумм нулевых операций возможна для тех, по которым нет чеков',
    acceptedAmountMinError: 'Минимальная вносимая сумма - 100 рублей',
    requestError: 'Не удалось отправить данные',
    fillFieldRequired: 'Заполните поле для поиска',
    leastOneOperation: 'Выберите хотя бы одну операцию',
    operationNotTransferForEdit: 'Редактировать возможно только переводы',
    operationFinalStatusForEdit: 'Невозможно изменить операцию в финальном статусе',
    oneSumFieldErr: 'Заполните одно из двух полей для суммы перевода',
    operationParamsErr: 'Не удалось опеределить тип операции',
    commissionNotExist: 'Комиссия не была расчитана',
    commissionErr: 'Ошибка при расчете комиссии',
    dateRangeErr: 'Начальная дата больше конечной',
    validateDatePastErr: 'Выберите дату меньше текущей',
    validateDateFutureErr: 'Выберите дату больше текущей',
    clientNotFound: 'Не найдено клиентов с таким номером документа',
    recipientIndexNotFound: 'Не удалось определить получателя',
    hardwareEmptyError: 'Не удалось определить привязку компьютера к лицензии ККМ',
    chooseGruop: 'Выберите Группу БПА из списка',
    chooseBpa: 'Выберите БПА из списка',
    choosePoint: 'Выберите точку из списка',
    chooseBpaAndPoint: 'Выберите БПА и точку из списка',
    chooseOperation: 'Выберите операцию из списка',
    chooseCheckToPrint: 'Выберите чеки из списка для начала печати',
    printInProgress: 'Печать еще идет',
    chooseOrganizarion: 'Выберите БПА',
    chooseFile: 'Выберите файл',
    notSupportedFile: 'Не поддерживаемый тип файла',
    inProgress: 'Операция в процесе обработки',
    cantDoThis: 'Не удалось выполнить запрос',
    tryFor30Seconds: 'Повторите запрос через 30 секунд',
    cantPringCheck: 'Не удалось распечатать чек',
    empFileParsingError: 'При обработке данного файла возникли ошибки',
    operatorHardwareServicePointError: 'Данный компьютер не привязан к точке',
    wrongEmailFormat: 'Введите почту в формате email@mail.ru',
    wrongPhoneFormat: 'Введите номер телефона в формате +71234567890',
    wrongForgotFormat: 'Введите корректные данные',
    payoutClientAndExpectedDifferent: 'Ожидаемые ФИО получателя и клиента не совпадают. Продолжение операции невозможно.'
  }
  
  export const messages = {
    newDocReady: 'Новый документ готов',
    passwordChange: 'Пароль успешно изменен',
    passwordReset: 'Пароль успешно сброшен',
    elementsRemoved: 'Элементы удалены',
    elementRemoved: 'Элемент удален',
    checksPrintingEnd: 'Чеки распечатаны',
    docForDownloadNotFound: 'Нет документов для скачивания',
    rejectRequestSent: 'Заявка на аннулирование операции отправлена',
    orgUpdated: 'БПА был успешно обновлен',
    orgCreated: 'БПА был успешно создан',
    orgAddToGroup: 'БПА успешно привязан к группе',
    bpaSettingUpdated: 'Настройки БПА успешно обновлены',
    orgRemoveFromGroup: 'БПА успешно отвязан от группе',
    pointUpdated: 'Точка была успешно обновлена',
    pointCreated: 'Точка была успешно создана',
    operationUpdated: 'Операция была успешно обновлена',
    operationCreated: 'Операция была успешно создана',
    groupUpdated: 'Группа БПА была успешно обновлена',
    groupCreated: 'Группа БПА была успешно создана',
    fileDeleted: 'Файл был успешно удален',
    userUpdated: 'Сотрудник был успешно обновлен',
    userCreated: 'Сотрудник был успешно создан',
    fileCreated: 'Файл был успешно добавлен',
    fileUpdated: 'Файл был успешно обновлен',
    limitUpdated: 'Лимит был успешно обновлен',
    limitCreated: 'Лимит был успешно создан',
    limitRemoved: 'Лимит был успешно удален',
    tarifFieldUpdated: 'Тарифные поля были успешно обновлены',
    tarifFieldCreated: 'Тарифные поля были успешно созданы',
    operationConfirm: 'Операция была успешно подтверждена',
    operationsConfirm: 'Операции были успешно подтверждены',
    passwordSendToEmail: 'Новый пароль был отправлен на указанную почту',
    settingsUpdated: 'Настройки успешно обновлены',
    fileUrlUpdated: 'Ссылка на файл была успешно обновлена',
    reportAddToQueue: 'Отчет добавлен в очередь для формирования',
    proposalCreate: 'Заявка на привязку отправлена',
    proposalUpdateApproved: 'Заявка успешно одобрена',
    proposalUpdateRemoved: 'Заявка успешно отклонена',
    pointUpdateApproved: 'Компьютер успешно привязан к точке',
    pointUpdateRemoved: 'Компьютер успешно отвязан от точки',
    hardwareRemoved: 'Компьютер успешно отвязан',
    proposalFromUserExist: 'Сообщите код Администратору',
    copyToClipboard: 'Текст скопирован в буфер обмена',
    checkEmpFileAndConfirm: 'Проверьте данные и нажмите подтвердить',
    chooseEmpFile: 'Выберите файл с данными для обработки',
    clickParsing: 'Нажмите обработать',
    parsingProgress: 'Обработка',
    passwordChanged: 'Пароль был успешно изменен',
    setAmountFromAdminSuccess: 'Сумма для нулевой операции успешно установлена',
    checkAddToQueue: 'Чек успешно добавлен в очередь',
    jobComplete: 'Планировщик выполнил задачу',
    accessElementRemove: 'Запись о печати успешно удалена',
    proposalAcepted: 'Ваша заявку успешно принята в обработку',
    operationCheckFlagRemove: 'Флаг печати чека сброшен',
    rejectSdComplete: 'Флаги для аннулирования после SD проставлены',
    selectAll: 'Выбрать все',
    unselectAll: 'Отменить выбор',
    docSigned: 'Документ подписан сертификатом БПА',
    endWihtoutCheck: 'Вы можете продолжить совершение операции. Обратите внимание, что фискальный чек также не будет распечатан при подтверждении до тех пор, пока служба поддержки NABIX Partner не устранит проблему.',
    checkEmpFileViaApi: 'После редактирования необходимо проверить возможность изменения сотрудников. Для этого нажмите проверить',
  }
  