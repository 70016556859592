import { messages } from './../../common/constants/messages';
import { takeUntil } from 'rxjs/operators';
import { DataService } from './../../common/services/data.service';
import { environment } from './../../../environments/environment';
import { API_LOGIN, API_GET_SELF, API_FORGOT, API_CAPTCHA_PARAMS } from './../../../route/api.path';
import { Component, OnInit, OnDestroy, ViewChild, HostListener } from '@angular/core';
import { Store } from '@ngxs/store';
import { Subscription, Subject } from 'rxjs';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { errorMessages } from 'src/app/common/constants/messages';
import { NotifyService } from 'src/app/common/services/notify.service';


@Component({
  selector: 'app-unistream',
  templateUrl: './unistream.component.html',
  styleUrls: ['./unistream.component.styl']
})
export class UnistreamComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<void> = new Subject<void>()
  subs = new Subscription()

  formGroup: FormGroup
  fullName: FormControl
  phone: FormControl
  email: FormControl
  orgName: FormControl
  captcha: FormControl

  currentRequests = [API_LOGIN, API_GET_SELF, API_FORGOT]

  captchaSiteKey = environment.captchaSiteKey

  constructor(
    private data: DataService,
    private notify: NotifyService) { }

  ngOnInit() {
    this.createFormControl()
  }

  createFormControl(): void {
    this.fullName = new FormControl('', [Validators.required, Validators.minLength(3)])
    this.phone = new FormControl('', [Validators.required, Validators.minLength(10), Validators.maxLength(10)])
    this.email = new FormControl('', [Validators.required])
    this.orgName = new FormControl('', [Validators.required, Validators.minLength(3)])
    this.captcha = new FormControl('', [Validators.required])

    this.formGroup = new FormGroup({
      FullName: this.fullName,
      Phone: this.phone,
      Email: this.email,
      OrgName: this.orgName,
      Captcha: this.captcha,
    })
  }

  ngOnDestroy() {
    this.subs.unsubscribe()
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }

  validateEmmailOrPhone(value: string, isPhone = true): boolean {
    if ((isPhone && (/^(\+?[7][0-9]\d{9}\)?)$/.test(String(value)))) ||
      (!isPhone && (/^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/.test(String(value))))) {
      return true
    }
    this.notify.error(isPhone ? errorMessages.wrongPhoneFormat : errorMessages.wrongEmailFormat)
    return false
  }

  get f() { return this.formGroup.controls }

  get fv() { return this.formGroup.value }

  get phoneNumber(): string {
    return `+7${this.fv.Phone}`
  }

  captchaCahnged(captchaResponse: string) {
    this.formGroup.controls.Captcha.setValue(captchaResponse)
  }

  resetFormControls(): void {
    this.formGroup.controls.FullName.setValue('')
    this.formGroup.controls.Phone.setValue('')
    this.formGroup.controls.Email.setValue('')
    this.formGroup.controls.OrgName.setValue('')
  }

  onSubmit() {
    if (this.formGroup.invalid ||
      !this.validateEmmailOrPhone(this.phoneNumber) ||
      !this.validateEmmailOrPhone(this.fv.Email, false)) {
      return;
    }
    this.data.createProposalOrganizationRegister({
      FullName: this.fv.FullName,
      OrganizationName: this.fv.OrgName,
      Phone: this.phoneNumber,
      Email: this.fv.Email,
      Captcha: this.fv.Captcha,
    }).pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
        this.resetFormControls()
        this.notify.success(messages.proposalAcepted)
      }, error => {
        this.resetFormControls()
        this.notify.error(error.message)
        console.error(error)
      })
  }
}
