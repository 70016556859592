import { Injectable } from '@angular/core'
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http'
import { Observable, throwError } from 'rxjs'
import { map, catchError, finalize, share } from 'rxjs/operators'
import { Store } from '@ngxs/store'
import { environment } from 'src/environments/environment'
import { StartLoading, EndLoadingSuccess, EndLoading, Error } from '../actions/loading.action'
import { notNullOrUndefined } from '../common/helpers/data.helper'
import { errorMessages } from '../common/constants/messages'

// Передаем файлы
export const fileDataAcceptUrls = [
  'simple-captcha-endpoint.ashx'
]

export const HTTP_METHODS = {
  GET: 'GET',
  HEAD: 'HEAD',
  POST: 'POST',
  PUT: 'PUT',
  PATCH: 'PATCH',
  DELETE: 'DELETE',
  CONNECT: 'CONNECT',
  OPTIONS: 'OPTIONS',
  TRACE: 'TRACE'
}

@Injectable()
export class BaseUrlInterceptor implements HttpInterceptor {

  constructor(
    private store: Store) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if ((request.method === HTTP_METHODS.POST ||
      request.method === HTTP_METHODS.PUT ||
      request.method === HTTP_METHODS.PATCH) &&
      !notNullOrUndefined(request.body)) {
        return
      }
    const key = request.url
    this.store.dispatch(new StartLoading({ key }))
    const apiReq = this.initUrl(request)
    return next.handle(apiReq).pipe(
      map(event => {
        this.store.dispatch(new EndLoadingSuccess({ key }))
        return event
      }),
      catchError(({ error, status }) => {

        if (status === 401 && !location.href.includes('/auth/')) {
          // this.behaviour.resetUserSource.next(true)
          // sessionStorage.removeItem('sessionToken')
          // this.router.navigate(['auth/login'])
        }
        else {

          if (typeof error === 'string') {
            try {
              error = JSON.parse(error)
            } catch { }
          }

          let message = error && error.message ? error.message : errorMessages.defaultError
          if (status === 401) {
            message = errorMessages.needSignIn
          }

          this.store.dispatch(new Error({ message, key, status }))
          return throwError(error)
        }
      }),
      finalize(() => {
        this.store.dispatch(new EndLoading({ key }))
      })
    )
  }

  initUrl(request: HttpRequest<any>): HttpRequest<any> {
    // if (request.body && request.body.request && request.body.request === '1c') {
    //   return this.getMainReq(request, request.url, token)
    // }
    if (request.url.includes(environment.signServiceUrl)) {
      return request
    }
    if (request.url.startsWith('/assets/')) {
      return this.getMainReq(request, request.url)
    }
    return this.getMainReq(request, `${environment.apiUrl}/${request.url}`)
  }

  getServiceReq(request: HttpRequest<any>, url: string): HttpRequest<any> {
    const apiReq = request.clone({
      setHeaders: {
        accept: 'application/json',
        'Content-Type': 'application/json',
        'Cache-Control': 'public, max-age=691200, s-maxage=691200',
        Pragma: 'public'
      },
      url
    })
    return apiReq
  }

  getMainReq(request: HttpRequest<any>, url: string): HttpRequest<any> {
    const indexFileAccept = fileDataAcceptUrls.findIndex(x => request.url.includes(x))
    const apiReq = request.clone({
      setHeaders: indexFileAccept > -1 ?
      {
       // Authorization: token ? `JwtUS2 ${token}` : token,
        accept: 'image',
        'Cache-Control': 'public, max-age=691200, s-maxage=691200',
        Pragma: 'public'
      } : {
     //   Authorization: token ? `JwtUS2 ${token}` : token,
        accept: 'application/json',
        'Content-Type': 'application/json',
        'Cache-Control': 'public, max-age=691200, s-maxage=691200',
        Pragma: 'public'
      },
      url
    })

    return apiReq
  }
}

