export class IsLoading {
    static readonly type = '[Loading] IsLoading'
    constructor(public payload: { key: string }) {}
  }
  export class StartLoading {
    static readonly type = '[Loading] StartLoading'
    constructor(public payload: { key: string }) {}
  }
  export class EndLoading {
    static readonly type = '[Loading] EndLoading'
    constructor(public payload: { key: string }) {}
  }
  export class EndLoadingSuccess {
    static readonly type = '[Loading] EndLoadingSuccess'
    constructor(public payload: { key: string }) {}
  }
  export class Error {
    static readonly type = '[Loading] Error'
    constructor(public payload: { message: string, key: string, status: number }) {}
  }
  
  export class ResetLoading {
    static readonly type = '[Loading] Reset'
  }
  