import { Component, Input, Output, EventEmitter } from '@angular/core'
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.styl']
})
export class CheckboxComponent {

  @Input() label: string
  @Input() name: ''
  @Input() pad = false
  @Input() hintBottom? = ''
  @Input() parentForm?: FormGroup
  @Input() state = false
  @Output() stateChange = new EventEmitter<boolean>()

  id = Math.random()

  get isReactive(): boolean {
    return !!this.parentForm
  }

  dataChange(): void {
    this.stateChange.emit(this.isReactive ? this.parentForm.value[this.name] : this.state)
  }
}
