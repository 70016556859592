import { RecaptchaModule } from 'ng-recaptcha';
import { DataService } from './common/services/data.service';
import { ClipboardDirective } from './common/derectives/clipboard.derective';
import { CheckboxComponent } from './pages/common/checkbox/checkbox.component';
import { InputComponent } from './pages/common/input/input.component';
import { UnistreamComponent } from './pages/unistream/unistream.component';
import { HeaderComponent } from './pages/common/header/header.component';

import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { LOCALE_ID, NgModule } from '@angular/core'
import { NgxsModule } from '@ngxs/store'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
// ngxs plugins
import { NgxsRouterPluginModule } from '@ngxs/router-plugin'
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin'
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin'
import { NgxsStoragePluginModule, STORAGE_ENGINE } from '@ngxs/storage-plugin'
import { AppComponent } from './app.component'
// add RU locale
import { registerLocaleData, DatePipe } from '@angular/common'
import localeRU from '@angular/common/locales/ru'

import { environment } from 'src/environments/environment'
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'
import { IConfig, NgxMaskModule } from 'ngx-mask'
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule } from '@angular/material/snack-bar'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatIconModule } from '@angular/material/icon'
import { MatDialogModule } from '@angular/material/dialog'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'

import { ClickOutsideModule } from 'ng-click-outside'
import { StickybitsModule } from 'ngx-stickybits'
import { MomentDateAdapter } from '@angular/material-moment-adapter'
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core'
import { ChartsModule } from 'ng2-charts';
import { AppRoutingModule } from 'src/route/app-route.module'
import { MyStorageEngine } from 'src/storage.engine'
import { LoadingState } from './state/loading.state'
import { BaseUrlInterceptor } from './interceptors/api.interceptor'
import { NOTYF, notyfFactory } from './common/services/notyf.token';
import { NotifyService } from './common/services/notify.service';
import { AutocompleteDirective } from './common/derectives/autocomplete.directive';
import { DisabledDirective } from './common/derectives/disabled.derective';
import { ReadonlyDirective } from './common/derectives/readonly.derective';
import { TooltipDirective } from './common/derectives/tooltip.directive';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD.MM.YYYY',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
}

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null

registerLocaleData(localeRU, 'ru')

@NgModule({
  declarations: [
    AppComponent,
    // derectives
    ClipboardDirective,
    AutocompleteDirective,
    DisabledDirective,
    ReadonlyDirective,
    TooltipDirective,
    // common pages
    HeaderComponent,
    InputComponent,
    CheckboxComponent,
    // pages
    UnistreamComponent
  ],
  imports: [
    RecaptchaModule,
    ChartsModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NgxsModule.forRoot([
      LoadingState
    ],
      {
        developmentMode: !environment.production
      }
    ),
    NgxsRouterPluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsLoggerPluginModule.forRoot(),
    NgxsStoragePluginModule.forRoot(),
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(options),
    MatSnackBarModule,
    MatDatepickerModule,
    MatIconModule,
    ClickOutsideModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    StickybitsModule
  ],
  entryComponents:
    [
    ],
  providers: [
    { provide: LOCALE_ID, useValue: 'ru-RU' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BaseUrlInterceptor,
      multi: true,
    },
    {
      provide: STORAGE_ENGINE,
      useClass: MyStorageEngine
    },
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 5000 } },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    { provide: NOTYF, useFactory: notyfFactory },
    DatePipe,
    NotifyService,
    DataService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

