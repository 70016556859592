<div [style.margin-top.px]="pad ? 28 : 0">
  <span *ngIf="isReactive" [formGroup]="parentForm">
    <input
      class="checkboxInput"
      [id]="id"
      [formControlName]="name"
      (change)="dataChange()"
      type="checkbox"
    >
    <label [for]="id" class="checkboxLabel">
      {{ label }}
    </label>
  </span>
  <span *ngIf="!isReactive">
    <input
      class="checkboxInput"
      [id]="id"
      [(ngModel)]="state"
      (change)="dataChange()"
      type="checkbox"
    >
    <label [for]="id" class="checkboxLabel">
      {{ label }}
    </label>
  </span>
  <div class="hint-bottom t-align-left" *ngIf="!!hintBottom">{{ hintBottom }}</div>
  </div>
