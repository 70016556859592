import { largeMonths } from './../constants/common';
import { ISelectDataItem } from 'src/app/pages/common/input/input.component'

export function notNullOrUndefined(value: any): boolean {
  return value !== null && value !== undefined
}

export function getStartOfDay(date: any): Date {
  const d1 = new Date(date)
  d1.setHours(0, 0, 0, 0)
  return d1
}

export function cloneSelectDataItem(data: ISelectDataItem, important = false): ISelectDataItem {
  const result = {
    id: data.id,
    Id: data.Id,
    countryCode: data.countryCode,
    text: data.text,
    name: data.name,
    Label: data.Label,
    label: data.label,
    AlphaCode3: data.AlphaCode3,
    important,
  }
  return result
}

export function convertToKeyboard(value: string, key1: string, key2: string): string {
  let result = ''
  const temp = Object.assign([], value)
  const arr1 = Object.assign([], key1)
  const arr2 = Object.assign([], key2)
  temp.map(x => {
    const index = arr1.findIndex(y => y === x)
    result = index > -1 && arr2[index] ? result + arr2[index] : result + x
  })
  return result
}

export function checkDate(d: string): boolean {
  let err = false
  if (!d) {
    return false
  }
  err = checkDateArrayNumberErr(d.split(''))
  if (String(d).length === 10) {
    const dateTime = d.split('.')
    let date = new Date(Date.UTC(Number(dateTime[2]), Number(dateTime[1]) - 1, Number(dateTime[0]), 0, 0, 0))
    date = getStartOfDay(date)
    err = checkDateArrayErr(dateTime) || !(date > this.minDate && date < this.maxDate)
  }
  return err
}

export function checkDateArrayNumberErr(arr: string[]): boolean {
  let check = false
  arr.map((x, index) => {
    if (index !== 2 && index !== 5) {
      if (['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].indexOf(x) < 0) {
        check = true
      }
    }
  })
  return check
}

export function checkDateArrayErr(arr: string[]): boolean {
  if (arr.length !== 3) {
    return true
  }
  if (Number(arr[1]) > 12 || Number(arr[1]) <= 0) {
    return true
  }
  const index = largeMonths.findIndex(x => x === Number(arr[1]))
  let maxDay = 30
  if (index > -1) {
    maxDay = 31
  } else {
    if (Number(arr[1]) === 2) {
      maxDay = Number(arr[2]) % 4 === 0 ? 29 : 28
    }
  }
  if (Number(arr[0]) > maxDay || Number(arr[0]) <= 0) {
    return true
  }
  return false
}
