export const environment = {
    name: 'dev',
    production: false,
    hmr: false,
    apiUrl: 'https://us2-dev.nabix.ru:5000',
    signatureUrl: 'http://localhost:5021',
    signServiceUrl: 'http://localhost:5021',
    defaultKkm: 'https://localhost:5893//Execute',
    mainCurrencyCode: 'RUB',
    mainCountryTitle: 'Россия',
    mainCountryCode: 'RUS',
    mainCurrenySign: '₽',
    mainCurrencyTitle: 'Российский рубль',
    pageSize: 5,
    setAmountTry: 3,
    recipientOperationDays: 180,
    recipientOperationTop: 20,
    captchaSiteKey: '6LdCMLEZAAAAAPFiy_Xjp2sIDzEth7USeWm-hWqA'
};
