import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { fadeInOut, fadeInOutUp, fadeInOutUpStagged } from 'src/animation.components';
import { Subject, Observable, Subscription } from 'rxjs';
import { FormGroup, FormArray, FormBuilder } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { TransferState } from '@angular/platform-browser';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { keyBoardRus, keyBoardEng } from 'src/app/common/constants/common';
import { messages } from 'src/app/common/constants/messages';
import { getStartOfDay, cloneSelectDataItem, convertToKeyboard, checkDate } from 'src/app/common/helpers/data.helper';

interface IInvalid {
  required?: boolean,
  min?: { actual: string, min: number },
  max?: { actual: string, max: number },
  minlength?: { actualLength: number, requiredLength: number },
  maxlength?: { actualLength: number, requiredLength: number },
  pwdEmpty?: string,
  pwdSymbolErr?: string,
  pwdLengthErr?: string,
  pwdDontMatch?: string
}

export interface ISelectDataItem {
  id?: string
  Id?: string
  countryCode?: string
  text?: string
  name?: string
  Label?: string
  label?: string
  AlphaCode3?: string
  important?: boolean
  isActive?: boolean
}

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.styl'],
  animations: [fadeInOut, fadeInOutUp, fadeInOutUpStagged]
})
export class InputComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<void> = new Subject<void>()

  @Input() placeholder?: string = ''
  @Input() width?= 'full'
  @Input() label?= null
  @Input() wrapperClass?: string
  @Input() quickValidation = false
  @Input() multiSelect = false
  @Input() submitted = false
  @Input() minimaze = false
  @Input() invalid?= null
  @Input() invalidDescr?= ''
  @Input() showInvalid?= true
  @Input() name?= ''
  @Input() mask?: string = ''
  // @Input() kladr: boolean = false
  // @Input() isUtilities: boolean = false
  // @Input() utilityType: string = ''
  @Input() oneString: boolean = false
  @Input('maskPrefix') maskPrefix?: string = ''
  @Input() autocomplete: string = 'new-password'
  @Input('parentForm') parentForm?: FormGroup
  @Input('req') isRequired?: boolean = false
  @Input('type') inputType?: string = 'text'
  @Input() autofocus?= false
  @Input() hint?= false
  @Input() hintTop?= ''
  @Input() hintBottom?= ''
  @Input() selectData: ISelectDataItem[] = []
  // private _selectData: ISelectDataItem[] = []
  // @Input() set selectData(val: ISelectDataItem[]) {
  //   this._selectData = val
  //   this.selectDataAll = val
  //   this.initMultiSelect()
  // }
  @Input() importantIds: string[] = []
  @Input() maxLength = 100
  @Input() isLoading = false
  @Input() readonly = false
  @Input() selectArrow = true
  @Input() selectClear = true
  @Input() isOnlyText = false
  // @Input() cityMin = false
  @Input() operationName = ''
  @Input() contentMaxHeight = 200
  @Input() contentMinWidth = 0
  @Output() controlChange: EventEmitter<any> = new EventEmitter()

  @ViewChild('inputField', { static: false }) inputField: ElementRef
  @ViewChild('selectList', { static: false }) selectList: ElementRef
  @ViewChild('suggestList', { static: false }) suggestList: ElementRef
  @ViewChild('utilityList', { static: false }) utilitytList: ElementRef

  subs = new Subscription()

  selectDataAll: ISelectDataItem[] = []

  focusedInputId = null
  focusedSelectIndex = 0
  // focusedSuggestIndex = 0
  // focusedUtilitiyIndex = 0

  id: string
  // idSuggest: string
  // idUtility: string

  isSearchInput = false
  isSelectInput = false
  isDateInput = false
  isStringDateInput = false
  isCardInput = false
  isNumberInput = false
  isPasswordInput = false


  selectOpen = false

  searchUpdated: Subject<string> = new Subject<string>()

  minDate = new Date(1900, 0, 0)
  maxDate = new Date(2100, 0, 0)

  pwdHide = true

  pwdIcon = '/assets/img/icons/show.svg'

  isReq = false

  isQuickErr = false

  isMultiselectAll = false
  multiselectAllTitle = messages.selectAll

  multiSelectArr: FormArray

  multiSelectTitle = ''

  constructor(
    private store: Store,
    private fb: FormBuilder
  ) { }

  // get selectData(): ISelectDataItem[] {
  //   return this._selectData
  // }

  ngOnInit() {
    this.checkRequired()
    this.checkImportantIds()
    this.minDate = getStartOfDay(this.minDate)
    this.maxDate = getStartOfDay(this.maxDate)
    // if (this.kladr) {
    //   this.kladrParentListener()
    //   this.searchAddListener()
    // }
    // if (this.isUtilities) {
    //   this.searchUtilitiesAddListener()
    // }
    if (!this.isOnlyText) {
      this.isSearchInput = this.inputType === "search"
      this.isSelectInput = this.inputType === "select"
      this.isDateInput = this.inputType === "datepicker"
      this.isStringDateInput = this.inputType === "stringdate"
      this.isCardInput = this.inputType === "card"
      this.isNumberInput = this.inputType === "number"
      this.isPasswordInput = this.inputType === "password"
    }

    if (this.isDateInput) {
      this.dateChanged()
    }

    this.id = `input_${Math.random()}`.replace('0.', '')

    // this.idSuggest = `input_${Math.random()}`.replace('0.', '')

    // this.idUtility = `input_${Math.random()}`.replace('0.', '')

    // if (this.name === 'doc_Type') {
    //   this.newDocNameListener()
    // }
    this.initMultiSelect()
    this.subs.add(this.currentControl.valueChanges.subscribe(data => {
      if (this.multiSelect) {
        this.multiSelectTitle = data
      }
    }))
  }

  initMultiSelect(): void {
    if (!this.multiSelect) {
      return
    }
    let isAll = true
    this.multiSelectArr = new FormArray([])
    if (!!this.selectedItem) {
      const arr = this.selectedItem.split(', ') as string[]
      this.selectDataAll.map(x => {
        let check = arr.findIndex(y => y === x.label || y === x.name || x.countryCode || x.Label) > -1
        if (!check) {
          isAll = false
        }
        this.multiSelectArr.push(
          this.fb.group({
            checked: check
          })
        )
      })
      this.multiSelectTitle = this.selectedItem
    } else {
      isAll = false
      this.selectDataAll.map(x => this.multiSelectArr.push(
        this.fb.group({
          checked: false
        })
      ))
      this.multiSelectTitle = null
    }
    this.isMultiselectAll = isAll
    this.multiselectAllTitle = isAll ? messages.unselectAll : messages.selectAll
  }

  selectAll(): void {
    this.isMultiselectAll = !this.isMultiselectAll
    this.multiselectAllTitle = this.isMultiselectAll ? messages.unselectAll : messages.selectAll
    const control = this.multiSelectArr as FormArray
    this.multiSelectArr.controls.map((item, index) => {
      control.controls[index]['controls'].checked.setValue(this.isMultiselectAll)
    })

    if (!this.multiSelectTitle) {
      this.multiSelectTitle = ''
    }
    if (!this.isMultiselectAll) {
      this.multiSelectTitle = ''
    } else {
      const arr = []
      this.selectData.map(item =>
        arr.push(item.Label || item.text || item.name || item.label || item))
      this.multiSelectTitle = arr.filter(value => Object.keys(value).length !== 0).join(', ')
    }
    this.controlChange.emit(null)
    this.currentControl.setValue(this.multiSelectTitle)
    this.controlChange.emit(this.multiSelectTitle)
  }

  // checkModelErrorListener(): void {
  //   this.behaviour.checkModelError$
  //   .pipe(takeUntil(this.ngUnsubscribe))
  //   .subscribe(state => {
  //     if (state) {
  //       this.checkModelError()
  //     }
  //   })
  // }

  checkRequired(): void {
    const val = this.currentControl.value
    this.currentControl.setValue(null)
    this.isReq = this.currentControl.errors ? !!this.currentControl.errors.required : false
    this.currentControl.setValue(val)
  }

  checkImportantIds(): void {
    if (!!this.selectData && this.selectData.length > 0 && !!this.importantIds && this.importantIds.length > 0) {
      const result = []
      this.importantIds.map(x => {
        let index = this.selectData.findIndex(y => y.id === x || y.Id === x)
        if (index > -1) {
          result.push(cloneSelectDataItem(this.selectData[index], true))
        }
      })
      this.selectData.map(x => {
        let index = this.importantIds.findIndex(y => y !== x.id && y !== x.Id)
        if (index > -1) {
          result.push(cloneSelectDataItem(this.selectData[index]))
        }
      })
      this.selectData = result
    }
    if (!this.selectDataAll || this.selectDataAll.length === 0) {
      this.selectDataAll = this.selectData
    }
  }

  // searchAddListener(): void {
  //   this.searchUpdated.pipe(debounceTime(1000)).subscribe(query => {
  //     this.kladrSuggest()
  //   })
  // }

  // searchUtilitiesAddListener(): void {
  //   this.searchUpdated.pipe(debounceTime(1000)).subscribe(query => {
  //     this.store.dispatch(new GetUtilityRecipient(query, this.utilityType))
  //   })
  //   this.subs.add(this.utilityRecipient$.subscribe(rec => {
  //     this.utilityRecipients = rec
  //   }))
  // }

  onSearch($event): void {
    this.searchUpdated.next($event.target.value)
  }

  getWrapperClass(): string {
    return `inputBox ${this.wrapperClass ? this.wrapperClass : ''} ${this.label ? ' inputBox--withLabel' : ''} ${this.isPasswordInput ? 'p-relative' : ''} ${!!!this.label ? 'no-label' : ''}`
  }

  selectorInputSearch(e) {
    // if (!this.selectDataAll || this.selectDataAll.length === 0) {
    //   this.selectDataAll = this.selectData
    // }
    if (this.readonly) return
    const { value } = e.target
    if (value) this.selectOpen = true
    const valKey1 = convertToKeyboard(value, keyBoardRus, keyBoardEng)
    const valKey2 = convertToKeyboard(value, keyBoardEng, keyBoardRus)
    const foundValue = this.selectData.find(v => v.Label === valKey1 || v.text === valKey1 || v.name === valKey1 || v.label === valKey1 || v.Label === valKey2 || v.text === valKey2 || v.name === valKey2 || v.label === valKey2)
    if (foundValue) {
      this.currentControl.setValue(foundValue.Label || foundValue.text || foundValue.name || foundValue.label)
      this.controlChange.emit(foundValue.Label || foundValue.text || foundValue.name || foundValue.label)
      // if (this.operationName) {
      //   this.checkModelError()
      // }
      this.selectOpen = e.keyCode !== 13
    } else {
      this.selectData = this.selectDataAll.filter(x =>
        (x.Label && x.Label.toLocaleLowerCase().includes(valKey1.toLocaleLowerCase()) ||
          (x.text && x.text.toLocaleLowerCase().includes(valKey1.toLocaleLowerCase()) ||
            (x.name && x.name.toLocaleLowerCase().includes(valKey1.toLocaleLowerCase()) ||
              (x.label && x.label.toLocaleLowerCase().includes(valKey1.toLocaleLowerCase()))))) ||
        (x.Label && x.Label.toLocaleLowerCase().includes(valKey2.toLocaleLowerCase()) ||
          (x.text && x.text.toLocaleLowerCase().includes(valKey2.toLocaleLowerCase()) ||
            (x.name && x.name.toLocaleLowerCase().includes(valKey2.toLocaleLowerCase()) ||
              (x.label && x.label.toLocaleLowerCase().includes(valKey2.toLocaleLowerCase()))))))
      this.selectOpen = this.selectData && this.selectData.length > 0
    }
    // if (!this.selectData || this.selectData.length === 0) {
    //   this.selectData = this.selectDataAll
    // }
  }

  selectFromList(event) {
    if (!this.isSelectInput || this.readonly || !this.selectList || (this.selectList && !this.selectList.nativeElement)) return
    const el = this.selectList.nativeElement
    const { length } = el.children
    if (event.keyCode === 40) {
      if (length > this.focusedSelectIndex + 1) {
        this.focusedSelectIndex++
        // const test = document.getElementById(`select${this.focusedSelectIndex}`) as HTMLElement
        // test.scrollIntoView()
      }
    }
    if (event.keyCode === 38) {
      if (0 <= this.focusedSelectIndex - 1) {
        this.focusedSelectIndex--
        // const test = document.getElementById(`select${this.focusedSelectIndex}`) as HTMLElement
        // test.scrollIntoView()
      }
    }
    if (event && (event.keyCode === 13 || event.keyCode === 38 || event.keyCode === 40) &&
      el && el.children && el.children[this.focusedSelectIndex] &&
      el.children[this.focusedSelectIndex].dataset &&
      el.children[this.focusedSelectIndex].dataset.value) {
      const val = el.children[this.focusedSelectIndex].dataset.value
      this.currentControl.setValue(val)
      this.controlChange.emit(val)
      // if (this.operationName) {
      //   this.checkModelError()
      // }
      this.selectOpen = event.keyCode !== 13
    } else {
      this.selectOpen = false
    }
  }


  ngOnDestroy() {
    // if (this.kladr || this.isUtilities) {
    //   this.searchUpdated.unsubscribe()
    // }
    this.subs.unsubscribe()
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }

  onControlChanged(): void {
    if (this.isStringDateInput) {
      this.checkDate()
      return
    }
    this.controlChange.emit(this.currentControl.value)
  }

  checkDate(): void {
    if (!this.isStringDateInput) {
      return
    }
    this.invalid = checkDate(this.currentControl.value)
    this.controlChange.emit(this.currentControl.value)
  }

  // kladrSuggest(): void {
  //   if (this.kladrRequest) return
  //   this.kladrRequest = true
  //   const payload = {
  //     request: this.kladrService.getKladrRequest(
  //       this.currentControl.value.replace(this.suggestShort, ''),
  //       this.name === 'state' ? 'region' : this.name,
  //       this.oneString,
  //       this.cityMin
  //     )
  //   }
  //   if (this.name === KladrEnity.street && !this.common.cityId) {
  //     return
  //   }
  //   this.kladrService.getKladrSuggest(payload)
  //     .pipe(takeUntil(this.ngUnsubscribe))
  //     .subscribe(data => {
  //       this.suggest = null
  //       const prom = JSON.parse(data) as IKladrResponse
  //       if (this.oneString && prom && prom.result) {
  //         const result = []
  //         prom.result.map(x => {
  //           if (x.contentType === 'region' || x.contentType === 'district' || x.contentType === 'city') {
  //             result.push(x)
  //           }
  //         })
  //         prom.result = result
  //       }
  //       this.suggest = prom
  //       this.suggestOpen = !!this.suggest
  //       if (this.suggest && this.suggest.result != null) {
  //         this.suggest.result.map(x => {
  //           x.legend = this.getKladrLocationSuggest(x)
  //           x.oneLegend = this.getKladrFullLocationSuggest(x)
  //         })
  //       }
  //       this.kladrRequest = false
  //     }, error => {
  //       this.kladrRequest = false
  //       console.error(error)
  //     })
  // }

  // getSuggestTitle(item: any, fromHtml = false): string {
  //   const type = this.getKladrShortTypeTitle(item)
  //   if (!fromHtml) {
  //     this.suggestShort = type
  //   }
  //   return this.name === 'state' && type !== 'г.' && type !== 'Респ.' ? `${item.name} ${type}` : `${type} ${item.name}`
  // }

  // getKladrShortTypeTitle(item: any): string {
  //   const type = item.typeShort === 'край' ||
  //     item.typeShort === 'аллея' ||
  //     item.typeShort === 'проезд' ||
  //     String(item.typeShort).indexOf('-') > -1
  //     ?
  //     item.typeShort :
  //     `${item.typeShort}.`
  //   return type
  // }

  // getKladrFullLocationSuggest(item: any): string {
  //   let loc = ''
  //   let ids = []
  //   if (item.parents != null && item.parents.length > 0) {
  //     const parents = item.parents
  //     parents.reverse().map(x => {
  //       const index = ids.findIndex(y => y === x.id)
  //       if (index < 0) {
  //         ids.push(x.id)
  //         const result = this.getKladrFullTitle(x)
  //         loc = `${loc}${result}, `
  //       }
  //     })
  //     const index = ids.findIndex(y => y === item.id)
  //     if (index < 0) {
  //       ids.push(item.id)
  //       const name = this.getSuggestTitle(item)
  //       loc = `${loc}${name}`
  //     } else {
  //       loc = loc.substring(0, loc.length - 2)
  //     }
  //     return loc
  //   }
  //   const name = this.getSuggestTitle(item)
  //   loc = `${loc}${name}`
  //   return loc
  // }

  // getKladrLocationSuggest(item: any): string {
  //   let loc = ''
  //   if (item.parents != null && item.parents.length > 0) {
  //     loc = '('
  //     const parents = item.parents
  //     parents.reverse().map(x => {
  //       const result = this.getKladrFullTitle(x)
  //       loc = `${loc}${result}, `
  //     })
  //     loc = `${loc})`
  //     return loc.replace(', )', ')')
  //   }
  //   return null
  // }

  // getKladrFullTitle(item: any): string {
  //   const locType = this.getKladrShortTypeTitle(item)
  //   return locType !== 'г.' && locType !== 'Респ.' ? `${item.name} ${locType}` : `${locType} ${item.name}`
  // }

  // kladrParentListener(): void {
  //   this.behaviour.kladrParentChange$
  //     .pipe(takeUntil(this.ngUnsubscribe))
  //     .subscribe(data => {
  //       if (data) {
  //         const name = this.name === 'state' ? 'region' : this.name
  //         if (name === data.contentType) {
  //           const result = this.getKladrFullTitle(data)
  //           this.currentControl.setValue(result)
  //           this.controlChange.emit(result)
  //           // if (this.operationName) {
  //           //   this.checkModelError()
  //           // }
  //         }
  //       }
  //     })
  // }

  // newDocNameListener(): void {
  //   this.behaviour.newDocsStatus$
  //     .pipe(takeUntil(this.ngUnsubscribe))
  //     .subscribe(data => {
  //       if (data) {
  //         this.currentControl.setValue(data)
  //         this.controlChange.emit(data)
  //         // if (this.operationName) {
  //         //   this.checkModelError()
  //         // }
  //       }
  //     })
  // }

  inputKeyUp(event: any): void {
    if (this.disabled || this.readonly) return
    if (this.isSelectInput) {
      this.selectorInputSearch(event)
      return
    }
  }

  inputKeyDown(event: any): void {
    if (this.disabled || this.readonly) return
    if (this.isSelectInput) {
      this.selectFromList(event)
      return
    }
    // if (this.kladr) {
    //   this.kladrEnter(event)
    //   return
    // }
  }

  selectorInputBlur(e) {
    let val = this.selectedItem

    if (this.selectedItem) {
      if (this.selectedItem !== e.target.value) {
        this.clearCurrentValue()
      }
    }

    this.currentControl.setValue(val)
    this.controlChange.emit(val)
    // if (this.operationName) {
    //   this.checkModelError()
    // }
  }

  selectorClicked(item: any, i: number) {
    if (this.readonly) return
    const val = item.Label || item.text || item.name || item.label
    if (!this.multiSelect) {
      this.controlChange.emit(null)
      this.currentControl.setValue(val)
      this.controlChange.emit(val)
      this.selectOpen = false
    } else {
      if (!this.multiSelectTitle) {
        this.multiSelectTitle = ''
      }
      const arr = this.multiSelectTitle.split(', ')
      const index = arr.findIndex(x => x === val)
      if (index < 0) {
        arr.push(val)
      } else {
        arr.splice(index, 1)
      }
      this.multiSelectTitle = arr.filter(value => Object.keys(value).length !== 0).join(', ')
      this.controlChange.emit(null)
      this.currentControl.setValue(this.multiSelectTitle)
      this.controlChange.emit(this.multiSelectTitle)
      const control = this.multiSelectArr as FormArray
      control.controls[i]['controls'].checked.setValue(index < 0)
      this.isMultiselectAll = this.multiSelectArr.value.findIndex(x => !x.checked) > -1 ? false : true
      this.multiselectAllTitle = this.isMultiselectAll ? messages.unselectAll : messages.selectAll
    }
  }

  // kladrEnter(event: any): void {
  //   if (!this.suggestList || this.readonly) return
  //   const length = this.suggest && this.suggest.result ? this.suggest.result.length : 0
  //   if (event.keyCode === 40) {
  //     if (length > this.focusedSuggestIndex + 1) {
  //       this.focusedSuggestIndex++
  //       // const test = document.getElementById(`suggest${this.focusedSuggestIndex}`) as HTMLElement
  //       // test.scrollIntoView()
  //     }
  //   }
  //   if (event.keyCode === 38) {
  //     if (0 <= this.focusedSuggestIndex - 1) {
  //       this.focusedSuggestIndex--
  //       // const test = document.getElementById(`suggest${this.focusedSuggestIndex}`) as HTMLElement
  //       // test.scrollIntoView()
  //     }
  //   }
  //   if (event && (event.keyCode === 13 || event.keyCode === 38 || event.keyCode === 40) && this.suggest && this.suggest.result) {
  //     event.stopPropagation()
  //     event.preventDefault()
  //     this.suggestClicked(this.suggest.result[this.focusedSuggestIndex > -1 ? this.focusedSuggestIndex : 0], event.keyCode === 13)
  //   } else {
  //     this.suggestOpen = false
  //   }
  // }

  // utilityRecipientClicked(item: IUtilityRecipientItem, isClose = true) {
  //   if (this.readonly) return
  //   this.controlChange.emit(null)
  //   this.utilityOpen = !isClose
  //   this.behaviour.setUtilityRecipientSource.next(item)
  // }

  // suggestClicked(item, isClose = true) {
  //   if (this.readonly) return
  //   this.controlChange.emit(null)
  //   const result = this.oneString ? item.oneLegend : this.getSuggestTitle(item)
  //   this.currentControl.setValue(result)
  //   this.controlChange.emit(result)
  //   // if (this.operationName) {
  //   //   this.checkModelError()
  //   // }
  //   if (item && item.parents !== null) {
  //     item.parents.map(x => this.behaviour.kladrParentChangeSource.next(x))
  //   }
  //   if (this.name === KladrEnity.city) {
  //     this.common.cityId = item.id
  //   }
  //   if (this.name === KladrEnity.region || this.name === 'state') {
  //     this.common.regionId = item.id
  //   }
  //   if (this.name === KladrEnity.district) {
  //     this.common.districtId = item.id
  //   }
  //   if (item.zip && !this.oneString) {
  //     this.behaviour.zipChangeStatusSource.next(item.zip)
  //   }
  //   this.suggestOpen = !isClose
  // }

  selectorClickedOutside(e) {
    this.selectOpen = false
    this.selectorInputBlur(e)
  }

  // suggestClickedOutside(e) {
  //   this.suggestOpen = false
  //   this.selectorInputBlur(e)
  // }

  // utilityClickedOutside(e) {
  //   this.utilityOpen = false
  //   this.selectorInputBlur(e)
  // }

  clearSelect() {
    if (this.readonly) return
    this.clearCurrentValue()
    // this.selectOpen = true
    // this.inputField.nativeElement.focus()
  }

  // clearSuggest() {
  //   this.clearCurrentValue()
  //   this.suggestOpen = true
  //   this.inputField.nativeElement.focus()
  // }

  inputBlur(e) {
    this.focusedInputId = null
  }

  getLabelClass() {
    return this.label ? 'inputBox--withLabel' : ''
  }

  get currentControl() {
    return this.parentForm && this.parentForm.controls[this.name]
  }

  get selectedItem() {
    return this.currentControl && this.currentControl.value
  }

  clearCurrentValue() {
    this.currentControl.setValue(null)
    this.controlChange.emit(null)
    // if (this.operationName) {
    //   this.checkModelError()
    // }

  }

  getErrMsg(invalid: IInvalid) {
    if (this.quickValidation && !this.submitted && invalid) {
      if (!invalid.required) {
        this.isQuickErr = true
        return this.invalidDescr
      }
      if (invalid.required) {
        this.isQuickErr = false
        return
      }
    }
    this.isQuickErr = true
    if (this.isPasswordInput) {
      if (invalid.pwdEmpty) return invalid.pwdEmpty
      if (invalid.pwdSymbolErr) return invalid.pwdSymbolErr
      if (invalid.pwdLengthErr) return invalid.pwdLengthErr
      if (invalid.pwdDontMatch) return invalid.pwdDontMatch
    }
    if (this.invalidDescr) return this.invalidDescr
    else if (invalid.required && this.isDateInput) return `Укажите верную дату`
    else if (invalid.required) return ``
    else if (invalid.max && invalid.max.max) return `Введите сумму не более ${invalid.max.max}`
    else if (invalid.min && invalid.min.min) return `Введите сумму не менее ${invalid.min.min}`
    else if (invalid.maxlength && invalid.maxlength.requiredLength) return `Введите не более ${invalid.maxlength.requiredLength} знаков`
    else if (invalid.minlength && invalid.minlength.requiredLength) return `Введите не менее ${invalid.minlength.requiredLength} знаков`

    return `Введите корректные данные`
  }

  /*
  datepickerFilter = (d): boolean => {
    return d._i.year > 1990 && d < new Date()
  }
  */

  onNumberInputChange(e) {
    if (this.readonly || !e) return
    const check = e.charCode >= 48 && e.charCode <= 58 || e.charCode === 44 || e.charCode === 46
    if (!check) {
      e.preventDefault()
      return
    }
    if (this.isStringDateInput) {
      const { length } = e.target.value

      if (length === 2 || length === 5) e.target.value += '.'
    }
  }

  onCardInputChange(e) {
    if (this.readonly) return
    const { length } = e.target.value

    if (length === 4 || length === 9 || length === 14) e.target.value += ' '
  }

  get inputClass(): any {
    return {
      'inputBox-input': !this.minimaze,
      'inputBox-min': this.minimaze,
      'width-hint': !!this.hint,
      'inputBox-input--select': this.isSelectInput,
      'inputBox-input--search': this.isSearchInput,
      'pwd-input': this.isPasswordInput,
      'inputBox-input--fullWidth': this.width === 'full',
      'inputBox-input--halfWidth': this.width === 'half',
      'inputBox-input--withLabel': this.label,
      'inputBox-input--invalid': this.invalid && this.isQuickErr
    }
  }

  get disabled() {
    return this.parentForm.controls[this.name].disabled
  }

  trackBy(i) {
    return i
  }

  changePwd(): void {
    if (this.pwdHide) {
      this.pwdHide = false
      this.pwdIcon = '/assets/img/icons/hide.svg'
    } else {
      this.pwdHide = true
      this.pwdIcon = '/assets/img/icons/show.svg'
    }
  }

  dateChanged(): void {
    // TODO делаем это при отправке только
    // const m = moment()
    // const myDate = new Date(this.parentForm.controls[this.name].value)
    // const newDate = moment(myDate)
    // m.set(newDate.toObject())
    // this.currentControl.setValue(this.common.clearDate(m, true))
  }

  showControl(): void {
    console.log(this.currentControl)
    console.log(this.currentControl.status)
    console.log(this.currentControl.errors)
    console.log(this.currentControl.validator)
    console.log(!!this.currentControl.validator)
    console.log(this.required)
    // console.log(this.utilityType)
  }

  get required(): boolean {
    const _validator: any = this.currentControl.validator && this.currentControl.validator(this.currentControl);
    return _validator && _validator.required;
  }

  getUtilityRecipientItemTitle(val: string): string {
    return val ? val : '---'
  }
}
