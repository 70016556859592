<header>
  <nav [ngClass]="{ 'nav nav--guest': true, 'sticky': isStatic}">

    <div class="logo-wrapper pointer p-relative" routerLink="home" *ngIf="logoType === 'usual'">
      <img  src="../../assets/img/nav/nabix-partner-logo.svg" alt="NABIX PARTNERS" class="nav-logo">
      <!-- <div class="seporator-wrapper">
        <span class="logo-separator"></span>
      </div>
        <img src="../../assets/img/logos/uni-logo.svg" alt="UNISTREAM" class="nav-logo"> -->
  </div>

    <div class="logo-wrapper pointer p-relative" routerLink="home" *ngIf="logoType === 'newYear'">
      <img  src="../../assets/img/nav/nabix-new-year-logo2.svg" alt="NABIX PARTNERS" class="nav-logo new-year">
      <!-- <div class="seporator-wrapper" [style.margin-left.px]="135">
        <span class="logo-separator"></span>
      </div>
        <img src="../../assets/img/logos/uni-logo.svg" alt="UNISTREAM" class="nav-logo"> -->
  </div>

    <section class="nav-account">

    </section>
  </nav>
</header>
