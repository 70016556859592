<div
  [style.height.px]="invalid && showInvalid && 105"
  [ngClass]="getWrapperClass()"
  [formGroup]="parentForm"
  [style.opacity]="disabled ? .4 : 1"
>
  <label class="inputBox-label" [for]="id" *ngIf="label && isOnlyText" (click)="showControl()">
    {{ label }} {{selectedItem ? '- ' + selectedItem : ''}}
  </label>
  <label class="inputBox-label" [for]="id" *ngIf="label && !isOnlyText" (click)="showControl()">
    {{ label }} <span class="color-main" *ngIf="isReq">*</span>
  </label>
  <input autocomplete="new-password" name="hidden" type="text" style="display:none;">

  <input
  *ngIf="isPasswordInput"
  [ngClass]="inputClass"
  [id]="id"
  [type]="pwdHide ? 'password' : 'text'"
  autocomplete="new-password"
  [readonly]="readonly" appReadonly
  [placeholder]="placeholder"
  [formControlName]="name"
  (input)="(!disabled || !readonly) && !isSelectInput && onControlChanged()"
  (focus)="focusedInputId = id; (!disabled || !readonly) && isSelectInput && selectOpen = true"
  (click)="(!disabled || !readonly) && isSelectInput && selectOpen = true"
  (click)="(!disabled || !readonly)"
  (blur)="inputBlur($event)"
  (mouseover)="focusedInputId = id"
  (input)="onSearch($event)"
  (keyup)="inputKeyUp($event)"
  (keydown)="inputKeyDown($event)"
  [autofocus]="(!disabled || !readonly) && autofocus"
  [maxLength]="maxLength"
  #inputField
>
<img
*ngIf="isPasswordInput"
class="pwd-hide"
[ngClass]="{'label-show': pwdHide && !!label, 'label-hide': !pwdHide && !!label, 'non-label-show': pwdHide && label == null, 'non-label-hide': !pwdHide && label == null}"
[src]="pwdIcon"
alt="Показать пароль"
(click)="changePwd()">

  <input
    *ngIf="!isDateInput&&!isCardInput&&!isNumberInput&&!isOnlyText&&!isStringDateInput&&!isPasswordInput" [ngClass]="inputClass"
    [id]="id" [type]="inputType"
    [placeholder]="placeholder"
    [formControlName]="name"
    [mask]="mask" [prefix]="maskPrefix"
    [autocomplete]="autocomplete" appAutocomplete
    [readonly]="readonly" appReadonly
    (input)="(!disabled || !readonly) && !isSelectInput && onControlChanged()"
    (focus)="focusedInputId = id; (!disabled || !readonly) && isSelectInput && selectOpen = true"
    (click)="(!disabled || !readonly) && isSelectInput && selectOpen = true"
    (click)="(!disabled || !readonly)"
    (click)="(!disabled || !readonly)"
    (blur)="inputBlur($event)"
    (mouseover)="focusedInputId = id"
    (input)="onSearch($event)"
    (keyup)="inputKeyUp($event)"
    (keydown)="inputKeyDown($event)"
    [autofocus]="(!disabled || !readonly) && autofocus"
    [maxLength]="maxLength"
    #inputField
  >

  <!-- <content
  *ngIf="suggest && suggest.result && suggest.result.length > 0 && suggestOpen"
  [@fadeInOutUpStagged]
  class="inputBox-selector"
  (clickOutside)="suggestClickedOutside($event)"
  delayClickOutsideInit="true"
  exclude="#{{idSuggest}}"
  #suggestList
  [style.max-height.px]="contentMaxHeight"
  [style.min-width.px]="contentMinWidth"
>
  <span
    [ngClass]="{
      'inputBox-selector-item': true,
      'inputBox-selector-item--active': selectedItem === item.Id || selectedItem === item.id || focusedSuggestIndex === i
    }"
    *ngFor="let item of suggest.result; let i = index; trackBy"
    (click)="suggestClicked(item)"
    [attr.data-value]="getSuggestTitle(item, true)"
  >
    <div id="suggest{{i}}" class="select-inner" *ngIf="!oneString">
      {{getSuggestTitle(item, true)}} <span class="loc-title" *ngIf="item.legend">{{item.legend}}</span>
    </div>
    <div id="suggest{{i}}" class="select-inner" *ngIf="oneString">
      {{item.oneLegend}}
    </div>
  </span>
</content> -->

  <!-- <content
  *ngIf="utilityRecipients && utilityRecipients.items && utilityOpen && !!selectedItem"
  [@fadeInOutUpStagged]
  class="inputBox-selector"
  (clickOutside)="utilityClickedOutside($event)"
  delayClickOutsideInit="true"
  exclude="#{{idUtility}}"
  #utilityList
  [style.max-height.px]="contentMaxHeight"
  [style.min-width.px]="contentMinWidth"
>
<span class="inputBox-selector-item" *ngIf="utilityRecipients.items.length === 0 else listUtility"><div id="utility0" class="select-inner">Совпадений не найдено</div></span>
<ng-template #listUtility>
<span
    [ngClass]="{
      'inputBox-selector-item': true,
      'inputBox-selector-item--active': selectedItem === item || focusedUtilitiyIndex === i
    }"
    *ngFor="let item of utilityRecipients.items; let i = index; trackBy"
    (click)="utilityRecipientClicked(item)"
  >
    <div id="utility{{i}}" class="select-inner">
      <div class="w-100">{{ item.name}}</div>
      <div class="utility-recipient-wrapper">
        <div class="utility-recipient row-2"><div class="utility-recipient-container"><span class="font-b">ИНН:</span> {{ getUtilityRecipientItemTitle(item.inn) }}</div></div>
        <div class="utility-recipient row-2"><div class="utility-recipient-container"><span class="font-b">КПП:</span> {{ getUtilityRecipientItemTitle(item.kpp) }}</div></div>
      </div>
      <div class="utility-recipient-wrapper">
        <div class="utility-recipient row-2"><div class="utility-recipient-container"><span class="font-b">Банк:</span> {{ getUtilityRecipientItemTitle(item.bankName) }}</div></div>
        <div class="utility-recipient row-2"><div class="utility-recipient-container"><span class="font-b">БИК:</span> {{ getUtilityRecipientItemTitle(item.bic) }}</div></div>
      </div>
      <div class="utility-recipient row-1"><div class="utility-recipient-container"><span class="font-b">Счет:</span> {{ getUtilityRecipientItemTitle(item.account) }}</div></div>
      <div class="utility-recipient row-1"><div class="utility-recipient-container"><span class="font-b">Кор. счет:</span> {{ getUtilityRecipientItemTitle(item.corAccount) }}</div></div>
      <div class="utility-recipient-wrapper">
        <div class="utility-recipient row-2"><div class="utility-recipient-container"><span class="font-b">УК:</span> {{ getUtilityRecipientItemTitle(item.company) }}</div></div>
        <div class="utility-recipient row-2"><div class="utility-recipient-container"><span class="font-b">ОКТМО:</span> {{ getUtilityRecipientItemTitle(item.oktmo) }}</div></div>
      </div>
      <div class="utility-recipient row-1" *ngIf="!!item.accountDescription"><div class="utility-recipient-container">{{ item.accountDescription }}</div></div>
    </div>
  </span>
</ng-template>
</content> -->

  <input
  *ngIf="isNumberInput || isStringDateInput" [ngClass]="inputClass"
  [id]="id"
  [placeholder]="placeholder"
  [formControlName]="name"
  [autocomplete]="autocomplete" appAutocomplete
  [readonly]="readonly" appReadonly
  (mouseover)="focusedInputId = id"
  (keypress)="onNumberInputChange($event)"
  (input)="onControlChanged()"
  #inputField
>
  <input
    *ngIf="isCardInput" [ngClass]="inputClass"
    [id]="id" [type]="inputType"
    [placeholder]="placeholder"
    [formControlName]="name"
    [mask]="mask" [prefix]="maskPrefix"
    [autocomplete]="autocomplete" appAutocomplete
    [readonly]="readonly" appReadonly
    (focus)="focusedInputId = id; (!disabled || !readonly) && isSelectInput && selectOpen = true"
    (click)="(!disabled || !readonly) && isSelectInput && selectOpen = true"
    (blur)="inputBlur($event)"
    (mouseover)="focusedInputId = id"
    (keyup)="(!disabled || !readonly) && isSelectInput && selectorInputSearch($event)"
    (keydown)="(!disabled || !readonly) && isSelectInput ? selectFromList($event) : null"
    (keypress)="onCardInputChange($event)"
    [autofocus]="(!disabled || !readonly) && autofocus"
    [maxLength]="maxLength"
    (input)="onControlChanged()"
    #inputField
  >
  <span
    class="clear"
    *ngIf="isSelectInput && selectedItem && !disabled && selectClear"
    (click)="(!disabled || !readonly) && clearSelect()"
    [@fadeInOutUp]
  >×</span>

  <span *ngIf="isDateInput">
    <input
      [ngClass]="inputClass" [id]="id"
      [formControlName]="name" type="text"
      [placeholder]="placeholder" [matDatepicker]="picker"
      (focus)="focusedInputId = id"
      (dateChange)="dateChanged()"
      (blur)="focusedInputId = null"
      (mouseover)="focusedInputId = id"
      autocomplete="off" appAutocomplete
      [readonly]="readonly" appReadonly
      [maxLength]="10" [min]="minDate" [max]="maxDate"
      [autofocus]="autofocus"
      (input)="onControlChanged()"
    >

    <!-- (keypress)="onDateInputChange($event)" выше-->
    <button
      type="button"
      (click)="picker.opened ? picker.close() : picker.open()"
      class="inputBox-date"
      [ngClass]="{'disabled': disabled || readonly}"
      [disabled]="disabled || readonly"
    >
      <img src="/assets/img/icons/calendar.svg" alt="Выбрать дату">
    </button>
  </span>

  <button
    type="button"
    *ngIf='isSearchInput'
    class="inputBox-search"
    [ngClass]="{'disabled': disabled || readonly}"
    [disabled]="disabled || readonly"
  >
    <img src="/assets/img/icons/shape.svg" alt="Поиск">
  </button>
  <button
    [disabled]="disabled || readonly"
    type="button" (click)="selectOpen = !selectOpen"
    class="inputBox-select"
    *ngIf='isSelectInput && selectArrow && !minimaze' [ngClass]="{'active': selectOpen, 'disabled': disabled || readonly}"
  >
    <img src="/assets/img/icons/arrow-down-select.svg" title="Выбрать">
  </button>
  <span
    *ngIf="hint" class="inputBox-hint"
    appTooltip data-action="getHint" [tooltipId]="hint"
    [tooltipHint]="hint" [top]="hintTop"
  >?</span>
  <p
    *ngIf="invalid && showInvalid" [@fadeInOut]
    class="inputBox-invalidDescr"
  >
    {{ getErrMsg(invalid) }}
  </p>

  <content
    *ngIf="isSelectInput && selectOpen"
    [@fadeInOutUpStagged]
    class="inputBox-selector"
    (clickOutside)="selectorClickedOutside($event)"
    delayClickOutsideInit="true"
    exclude="#{{id}}"
    #selectList
    [style.max-height.px]="contentMaxHeight"
    [style.min-width.px]="contentMinWidth"
  >

  <span
  *ngIf="selectData && selectData.length > 0 && multiSelect"
  [ngClass]="{
    'inputBox-selector-item': true,
    'inputBox-selector-item--active': isMultiselectAll
  }"
  [@fadeInOutUp]
  (click)="selectAll()"
>
  <div id="select0" class="select-inner d-flex">
    <div class="d-inline-block multi-select-checkbox" [style.margin-right.px]="10">
      <app-checkbox
      [state]="isMultiselectAll"
      (change)="selectAll()"
    ></app-checkbox></div>
    <div *ngIf="multiSelect" class="d-inline-block multi-select-title-wrapper">
      <div class="multi-select-title">
        {{ multiselectAllTitle }}
      </div>
    </div>
  </div>
</span>

    <span
      [ngClass]="{
        'inputBox-selector-item': true,
        'inputBox-selector-item--active': selectedItem === item.Id || selectedItem === item.id || focusedSelectIndex === i
      }"
      *ngFor="let item of (selectData); let i = index; trackBy"
      [@fadeInOutUp]
      (click)="selectorClicked(item, i)"
      [attr.data-value]="item.Label || item.text || item.name || item.label || item"
    >
      <div id="select{{i}}" class="select-inner" [ngClass]="{'font-b': item.important || item.isActive, 'd-flex': multiSelect}">
        <div class="d-inline-block multi-select-checkbox" [style.margin-right.px]="10" *ngIf="multiSelect">
          <app-checkbox
          name="checked"
          [parentForm]="multiSelectArr.controls[i]"
        ></app-checkbox></div>
        <div *ngIf="!multiSelect" class="d-inline-block">
          {{ item.Label || item.text || item.name || item.label || item }}
        </div>
        <div *ngIf="multiSelect" class="d-inline-block multi-select-title-wrapper">
          <div class="multi-select-title">
            {{ item.Label || item.text || item.name || item.label || item }}
          </div>
        </div>
      </div>
      <div
        *ngIf="selectData && selectData.length === 0"
        [style.opacity]=".6"
      >
        Данные не найдены
      </div>
    </span>

    <span
      class="inputBox-selector-item"
      *ngIf="!selectData || selectData.length === 0"
      [@fadeInOutUp]
    >
      <div [style.opacity]=".6">
        {{ isLoading ? 'Загрузка...' : 'Список пуст' }}
      </div>
    </span>
  </content>
  <div class="hint-bottom" *ngIf="!!hintBottom">{{ hintBottom }}</div>
</div>
<mat-datepicker #picker appAutoModeSwitch></mat-datepicker>
