import {
  Directive,
  Input,
  HostListener,
  ElementRef,
  Renderer2
} from "@angular/core";
import { Store } from "@ngxs/store";
import { Navigate } from "@ngxs/router-plugin";

@Directive({
  selector: "[appTooltip]"
})
export class TooltipDirective {
  @Input() tooltipTitle: string = "";
  @Input() tooltipHint: string = "";
  @Input() tooltipType?: string = "";
  @Input() tooltipId?: string = "tooltip";
  @Input() top?: string = "";

  div: HTMLElement;
  text: string;
  oprationClickFn: () => void;

  constructor(
    private element: ElementRef,
    private renderer: Renderer2,
    private store: Store
  ) {
    this.oprationClickFn = renderer.listen(
      element.nativeElement,
      "click",
      this.onOperationClick.bind(this)
    );
  }

  onOperationClick({ target }) {
    let route = target.dataset.route
    if (!target) {
      return
    }
    if (!route && target.parentElement) {
      route = target.parentElement.dataset.route
      if (!route && target.parentElement.parentElement) {
        route = target.parentElement.parentElement.dataset.route
      }
    }
    if (route) this.store.dispatch(new Navigate([route]))
  }

  @HostListener("mouseenter")
  onMouseEnter(): void {
    if (!this.element.nativeElement.dataset.action) return;

    const el = this.getElement(this.tooltipId);
    if (el) {
      this.div = el;
      this.div.classList.add("tooltipCard--show");
    } else {
      this.div = this.renderer.createElement("div");
      this.div.className = `tooltipCard tooltipCard--show`;
      this.div.id = this.tooltipId;
      this.div.style.top = this.top;

      this.div.innerHTML = `<p>${this.tooltipHint}</p>`;
      this.div.className += " tooltipCard--hint";

      this.renderer.appendChild(this.element.nativeElement, this.div);
    }
  }

  @HostListener("mouseleave")
  onMouseLeave(): void {
    this.destroy();
  }

  ngOnDestroy(): void {
    this.destroy();
    this.oprationClickFn();
  }

  destroy(): void {
    if (!this.div) return;

    this.div.classList.remove("tooltipCard--show");
  }

  getElement(el: string) {
    return document.getElementById(el);
  }
}
