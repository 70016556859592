export const API = 'api'
export const V1 = 'v1'
export const V2 = 'v2'
export const ACCOUNT = 'account'
export const USERS = 'Users'
export const LOOKUP = 'lookup'
export const IDENTIFICATION = 'identification'
export const CLIENTS = 'clients'
export const OPERATIONS = 'operations'
export const ORGANIZATION = 'organization'
export const SERVICE_POINTS = 'ServicePoints'
export const GROUP = 'Group'
export const BLOCK = 'Block'
export const DOWNLOAD = 'Download'
export const FILTER = 'Filter'

export const API_LOGIN = `${API}/${ACCOUNT}/login`
export const API_LOGOUT = `${API}/${ACCOUNT}/logout/1`
export const API_FORGOT = `${API}/${ACCOUNT}/forgotPassword`
export const API_RESET_PASSWORD_FROM_ADMIN = `${API}/${ACCOUNT}/admin/ResetPassword`
export const API_GET_SELF = `${API}/${USERS}/current`
export const API_USER_ENTITIES = `${API}/${USERS}/entities`
export const API_GET_LIKED_USERS = `${API}/${USERS}`
export const API_STATS_FILTER = `${API}/Stats/Filter`
export const API_JOBS = `${API}/Job`

export const API_CLIENTS = `v2/${CLIENTS}`
export const API_GET_CLIENT_BY_PASSPORT = `v2/clients/search`
export const API_TRANSFER = `v2/definitions/Transfer`
export const API_GET_WITHDRAW_COUNTRIES = `${API_TRANSFER}/CountryData/${LOOKUP}`
export const API_GET_CURRENCIES = `${API_TRANSFER}/WithdrawCurrency/${LOOKUP}`
export const API_CALCULATE_COMMISSION = `${API}/${V1}/transfer/calculate`
export const API_CALCULATE_COMMISSION_BPA = `${API}/Tariff/CalculateBPACommission`
export const API_GET_COUNTRIES = `${API}/${V1}/countries`
export const API_GET_DOCUMENTS = `v2/${IDENTIFICATION}/schemas`
export const API_GET_DOCUMENT_DETAILS = `v2/${IDENTIFICATION}/schemas/documents`

export const API_OPERATIONS = `v2/${OPERATIONS}`
export const API_CHECK_KKM_DATA = `${API}/Kkm/data`
export const API_CHECK_KKM_EXPIRED = `${API}/Kkm/expired`
export const API_KKM_GENERATE_KEY = `${API}/Kkm/keys/generate`

export const API_SIGNATURE = `${V1}/Signature`

export const API_KLADR = `${API}/Kladr`



export const API_USER_SUMMARY = `${API}/Users/Summary`
export const API_GET_LIMIT_TYPES = `${API}/Limits/Types`
export const API_GET_LIMIT = `${API}/Limits/${FILTER}`
export const API_ADD_OR_UPDATE_POINT = `${API}/${SERVICE_POINTS}/Points`
export const APE_GET_TIME_ZONES = `${API}/${SERVICE_POINTS}/TimeZones`
export const API_GET_USERS = `${API}/Users/${FILTER}`
export const API_ADD_OR_UPDATE_USER = `${API}/Users/AddOrUpdate`
export const API_GET_POINTS = `${API}/${SERVICE_POINTS}/Points/${FILTER}`
export const API_GET_AVAILABLE_FOR_USER = `${API}/Users/Available`
export const API_GET_ORGANIZATION_OPERATIONS = `v2/operations/Organizations`
export const API_LIMITS = `${API}/Limits`

export const API_ADD_OR_UPDATE_ORGANIZATION = `${API}/Organizations`

export const API_FILE_PERMISSION = `${API}/File/CheckPermission`
export const API_BALANCE_UPDATE = `${API}/File/Balance`
export const API_USER_TEMPORARY = `${API}/${ACCOUNT}/temporary`
export const API_USER_TEMPORARY_CHANGE = `${API}/${ACCOUNT}/temporary/change`
export const API_USER_CHANGE_PWD = `${API}/${ACCOUNT}/ChangePassword`
export const API_BALANCES = `${API}/Balance/${FILTER}`

export const API_ORGANIZATION_TARIFF = `${API}/Tariff/tariffs`
export const API_ORGANIZATION_FILTER = `${API}/organizations/${FILTER}`
export const API_ORGANIZATION_TYPES = `${API}/organizations/types`
export const API_ORGANIZATION_SET_OR_REMOVE_OPERATION = `${API}/organizations/SetOperations`
export const API_ORGANIZATION_OPERATION_TYPES = `${API}/organizations/Operations/Types`
export const API_TRANSFER_REJECT = `v2/operations/transfer/reject`
export const API_CHECK_PRINT_CHECK = `​v2/operations/check​`

// export const API_OPERATION_FILTER_DATA = `v2/operations/${FILTER}/data​`
export const API_OPERATION_EDIT = `v2/operations/check/edit`

export const API_NON_COMMISSION_CHECK = `v2/operations/NonCommission`
export const API_USER_SETTINGS = `${API}/${USERS}/settings`
export const API_USER_PIN_CHECK = `${API}/${ACCOUNT}/Pin`
export const API_USER_TOKEN_CHECK = `${API}/${ACCOUNT}/Token`

export const API_SET_OPERATION_AMOUNT = `${API}/setAmount`
export const API_SET_OPERATION_AMOUNT_ADMIN = `${API}/setAmount/admin`
export const API_GET_OPERATION_AMOUNT = `${API}/getAmount`
export const API_CLIENT_RECIPIENTS = `${V2}/clients/recipients`

export const API_QUEUE = `${API}/Queue1C`
export const API_QUEUE_FILTER = `${API}/Queue1C/Filter`
export const API_QUEUE_MODULE = `${API}/Queue1C/module`
export const API_QUEUE_KKM = `${API}/QueueKKM`
export const API_QUEUE_KKM_CONFIRM = `${API}/QueueKKM/confirm`
export const API_QUEUE_KKM_TEST = `${API}/QueueKKM/test`
export const API_QUEUE_KKM_FILTER = `${API}/QueueKKM/${FILTER}`
export const API_QUEUE_KKM_COUNT = `${API}/QueueKKM/count`
export const API_QUEUE_COUNT = `${API}/Queue1C/count`
export const API_QUEUE_CHECK_TEST = `${API}/Queue1C/check/test`
export const API_QUEUE_CLEAR = `${API}/Queue1C/clear`
export const API_QUEUE_CONFIRM_REMOVE = `${API}/Queue1C/confirm`
export const API_GET_CLIENT_INFO = `${V2}/clients/info`
export const API_QUEUE_ADD_OPERATION_ELEMENT = `${API}/Queue1C/operation`

export const API_QUEUE_REPORT = `${API}/QueueReport`
export const API_QUEUE_REPORT_FILTER = `${API}/QueueReport/${FILTER}`
export const API_QUEUE_REPORT_REJECT = `${API}/QueueReport/reject`

export const API_GET_CHECK = `${API}/Cashbox/check`
export const API_CASHBOX = `${API}/Cashbox`
export const API_CASHBOX_OPERATION_FLAG = `${API}/Cashbox/operations/flags`
export const API_GET_CHECK_PREVIEW = `${API}/Cashbox/check/preview`
export const API_GET_CHECK_TEST = `${API}/Cashbox/check/test`
export const API_ORGANIZATION_ADD_OR_REMOVE_ALL_OPERATIONS = `${API}/Organizations/SetOperations`
export const API_UTILITIES_RECIPIENTS = `${API}/recipient/${FILTER}`
export const API_GROUP_FILTER = `${API}/Group/${FILTER}`
export const API_GROUP = `${API}/Group`
export const API_GROUP_TREE = `${API}/Group/Tree`
export const API_GROUP_ADD_OR_REMOVE_ORGANIZATION = `${API}/Group/Organization`
export const API_ORGANIZATION_BALANCE = `${API}/Organizations/balance`
export const API_ORGANIZATION_SETTINGS = `${API}/Organizations/Settings`
export const API_CASHBOX_ACCESS = `${API}/Cashbox/access`

export const API_HARDWARE = `${API}/Hardware`
export const API_HARDWARE_PROPOSAL = `${API}/Hardware/proposal`
export const API_HARDWARE_PROPOSAL_APPROVED = `${API}/Hardware/proposal/approved`
export const API_HARDWARE_PROPOSAL_FILTER = `${API}/Hardware/proposal/Filter`
export const API_HARDWARE_POINT = `${API}/Hardware/point`
export const API_ADMIN_NABIX_SETTINGS = `${API}/settings`

export const API_FILE_DOWNLOAD = `${API}/File`
export const API_FILE_UPLOAD_USERS = `${API}/File/${USERS}`
export const API_FILE_UPLOAD_USERS_CHECK = `${API}/File/${USERS}/Check`
export const API_FILE_UPLOAD_USERS_UPDATE = `${API}/File/${USERS}/Update`
export const API_FILE_DOWNLOAD_URLS = `${API}/File/urls`
export const API_FILE_DOWNLOAD_DOWNLOAD = `${API}/File/${DOWNLOAD}`
export const API_FILE_DOWNLOAD_FILTER = `${API}/File/${DOWNLOAD}/${FILTER}`
export const API_FILE_DOWNLOAD_AVAILABLE = `${API}/File/${DOWNLOAD}/Available`
export const API_OPERATIONS_KNP = `${API}/operations/knp`

export const API_ACCOUNT_RESTORE_ACCESS = `${API}/${ACCOUNT}/restore`
export const API_ACCOUNT_PARAMS = `${API}/${ACCOUNT}/params`


export const API_CAPTCHA_PARAMS = `${API}/Us2Captcha/CreateDNTCaptchaParams`
export const API_CAPTCHA_VALIDATE = `${API}/Us2Captcha/validate`
export const API_ACCOUNT_PROPOSAL_ORGANIZATION_REGISTER = `${API}/${ACCOUNT}/${ORGANIZATION}/Proposal`
