import { Injectable, Inject } from '@angular/core'
import { Observable } from 'rxjs'
import { NOTYF } from './notyf.token'
import { Notyf } from 'notyf'

@Injectable()
export class NotifyService {
  type: {
    info: 'info',
    success: 'success',
    warn: 'warn',
    error: 'error',
}

errQueue = []
errLast = []

errorTextActive = 0
inDev = 'В разработке'

  constructor(
    @Inject(NOTYF) private notify: Notyf
  ) { }

  inDevelopment(): void { this.notify.open({ type: 'info', message: this.inDev }) }

  info(message: string): void { this.notify.open({ type: 'info', message }) }
  success(message: string): void { this.notify.open({ type: 'success', message }) }
  warn(message: string): void { this.notify.open({ type: 'warn', message }) }
  error(message: string): void {
    if (this.errorTextActive > 3) {
      if (this.errQueue.findIndex(x => x === message) < 0 &&
      this.errLast.findIndex(x => x === message) < 0) {
        this.errQueue.push(message)
      }
    } else {
      this.errorTextActive++
      this.errLast.push(message)
      if (this.errLast.length > 4) {
        this.errLast.shift()
      }
      this.notify.open({ type: 'error', message })
      setTimeout(() => {
        this.errorTextActive--
        if (this.errQueue.length > 0) {
          const newMessage = this.errQueue.shift()
          this.error(newMessage)
        }
      }, 5500)
    }
  }

  show(type: string, message: string): void {
    this.notify.open({ type, message })
  }

}
