import { State, Selector, StateContext, Action } from '@ngxs/store'
import { StartLoading, EndLoading, EndLoadingSuccess, Error, ResetLoading } from '../actions/loading.action'


export class LoadingStateModel {
  loading: string[]
  error?: { message?: string, key: string, status: number }[]
}

const defaults = {
  loading: [],
  error: []
}

@State<LoadingStateModel>({
  name: 'loading',
  defaults
})
export class LoadingState {

  @Selector()
  static loadings({ loading }: LoadingStateModel) {
    return loading
  }

  @Selector()
  static errors({ error }: LoadingStateModel) {
    return error
  }

  @Action(StartLoading)
  StartLoading({ patchState, getState }: StateContext<LoadingStateModel>, { payload }) {

    const loading = getState()

    patchState({ loading: [ ...loading.loading, payload.key ] })
  }

  @Action(EndLoading)
  EndLoading({ patchState, getState }: StateContext<LoadingStateModel>, { payload }) {

    const loading = getState()

    if (!loading.loading.length) return

    const newLoading = loading.loading.filter(e => e !== payload.key)

    patchState({ loading: newLoading })
  }

  @Action(EndLoadingSuccess)
  EndLoadingSuccess({ patchState, getState }: StateContext<LoadingStateModel>, { payload }) {

    const loading = getState()

    if (!loading.error.length) return

    const newErr = loading.error.filter(e => e.key !== payload.key)

    patchState({ error: newErr })
  }

  @Action(Error)
  Error({ patchState, getState }: StateContext<LoadingStateModel>, { payload }) {

    const loading = getState()

    patchState({ error: [...loading.error, payload] })
  }

  @Action(ResetLoading)
  ResetLoading({ setState }: StateContext<LoadingStateModel>) {
    setState(defaults)
  }
}
